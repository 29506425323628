export default class MemberDiscountHelper {
  static shouldSeeMemberDiscountVariant = () => {
    // if not in control 15% variant
    const inControlVariant = window.experimentVariants &&
      window.experimentVariants.includes("member_discount_control");
    return !inControlVariant;
  }

  static getMemberDiscountVariant = () => {
    if (!MemberDiscountHelper.shouldSeeMemberDiscountVariant()) return null;

    const indexOfMemberDiscountVariant = window.experimentVariants.indexOf("member_discount_var");
    const indexOfCommaAfterVariant = window.experimentVariants.indexOf(",", indexOfMemberDiscountVariant);

    const lastIndex = indexOfCommaAfterVariant === -1 ? window.experimentVariants.length : indexOfCommaAfterVariant;
    const memberDiscountVariant = window.experimentVariants.substring(indexOfMemberDiscountVariant, lastIndex);
    if (memberDiscountVariant === "member_discount_var_c") return memberDiscountVariant;
    return "member_discount_var_b";
  }
}
