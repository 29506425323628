/* eslint-disable */
import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';
export default class TinySliderOverride extends TinySlider {
  postInit = () => {
    var _this3 = this;

    if (!this.slider || !this.slider.events) {
      if (this.count >= 4) {
        return this.props.onInit(false); // call initMetod anyway
      }

      this.count++;
      return setTimeout(this.postInit.bind(this), 100);
    }

    this.count = 0;
    var events = this.slider.events;
    var _this$props = this.props,
        onIndexChanged = _this$props.onIndexChanged,
        onTransitionStart = _this$props.onTransitionStart,
        onTransitionEnd = _this$props.onTransitionEnd,
        onTouchStart = _this$props.onTouchStart,
        onTouchMove = _this$props.onTouchMove,
        onTouchEnd = _this$props.onTouchEnd,
        onInit = _this$props.onInit;
    /* BIND EVENTS */
    // change draggin value to purge onClick event.

    events.on('transitionStart', function (info) {
      _this3.dragging = true;
      if (onTransitionStart) onTransitionStart(info);
    });
    events.on('transitionEnd', function (info) {
      _this3.dragging = false;
      if (onTransitionEnd) onTransitionEnd(info);
    });
    if (onIndexChanged) events.on('indexChanged', onIndexChanged);
    if (onTouchStart) events.on('touchStart', onTouchStart);
    if (onTouchMove) events.on('touchMove', onTouchMove);
    if (onTouchEnd) events.on('touchEnd', onTouchEnd); // call on Innit

    onInit(true);
  }
}
