/* eslint-disable quotes, object-shorthand*/

import * as React from "react";
import PropTypes from "prop-types";

export default class Star extends React.PureComponent {

  static propTypes = {
    "addWishListCallback": PropTypes.func.isRequired,
    "id": PropTypes.number.isRequired,
    "price": PropTypes.string.isRequired,
    "removeWishListCallback": PropTypes.func.isRequired,
    "sku": PropTypes.string.isRequired,
    "wishlisted": PropTypes.bool
  };

  static defaultProps = {"wishlisted": false};

  constructor (props) {
    super(props);
    this.wishlistStar = React.createRef();
  }

  componentDidUpdate ({"wishlisted": prevWishlisted}) {
    const {wishlisted} = this.props;
    if (!prevWishlisted && wishlisted) {
      const backgroundSize = parseInt(
        getComputedStyle(this.wishlistStar.current).
          backgroundSize.
          replace(/px/, ""),
        10
      );
      $(this.wishlistStar.current).
        animate({"backgroundSize": `${backgroundSize * 1.55}px`}, 200).
        animate({"backgroundSize": `${backgroundSize * 0.9}px`}, 200).
        animate({"backgroundSize": `${backgroundSize * 1.5}px`}, 200).
        animate({"backgroundSize": `${backgroundSize * 0.9}px`}, 200).
        animate({"backgroundSize": `${backgroundSize * 1.1}px`}, 200).
        animate({"backgroundSize": `${backgroundSize}px`}, 200);
    }
  }

  addWishListRequest = (event) => {
    event.preventDefault();
    const {addWishListCallback, ...props} = this.props;
    addWishListCallback(props.id, false);
  };

  removeWishListRequest = (event) => {
    event.preventDefault();
    const {removeWishListCallback, ...props} = this.props;
    removeWishListCallback(props.id, false);
  };

  toggleWishListRequest = (event) => {
    event.preventDefault();
    const {wishlisted} = this.props;
    wishlisted ? this.removeWishListRequest() : this.addWishListRequest();
  };

  render () {
    const {wishlisted} = this.props;
    return (
      <div
        className={`${wishlisted
          ? "selected"
          : "unselected"} wishlist-star`}
        onClick={wishlisted
          ? this.removeWishListRequest
          : this.addWishListRequest}
        ref={this.wishlistStar}
      >
        {[
          "wishlist/wishlist-heart-selected.svg",
          "wishlist/wishlist-heart-empty.svg",
          "wishlist/wishlist-heart-hover.svg"
        ].map(img =>
          <link
            href={`/assets/roxy/${img}`}
            key={img}
            rel="prefetch"
          />)}
      </div>
    );
  }
}
