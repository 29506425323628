/* eslint-disable max-len, react/jsx-no-bind */
import React, {useEffect, useState} from "react";
import EventsAPI from "../../../shared/modules/EventsAPI";
import PropTypes from "prop-types";
import {withCookies} from "react-cookie";

const PromoCodeBanner = ({cookies}) => {
  const [
    appliedPromoCode,
    setAppliedPromoCode
  ] = useState("");

  const [
    dismissed,
    setDismissed
  ] = useState(false);

  const handleUpdatedPromoCode = () => {
    setAppliedPromoCode(cookies.get("auto_applied_promo_code"));
  };

  useEffect(() => {
    window.addEventListener("appliedPromoCode", handleUpdatedPromoCode);
    setAppliedPromoCode(cookies.get("auto_applied_promo_code"));
    return () => {
      window.removeEventListener("appliedPromoCode", handleUpdatedPromoCode);
    };
  }, []);

  const handleLinkClicked = () => {
    const eventName = "clicked_shop_now";
    const eventDescription = `promo code banner from ${window.location.pathname}`;

    EventsAPI.trackUserEvent(
      eventName,
      eventDescription,
      cookies.get("anonymous_contact_id")
    );
  };

  const handleDismissed = () => {
    setDismissed(true);

    const eventName = "dismissed_promo_code_banner";
    const eventDescription = `from ${window.location.pathname}`;

    EventsAPI.trackUserEvent(
      eventName,
      eventDescription,
      cookies.get("anonymous_contact_id")
    );
  };

  return (
    <React.Fragment>
      {appliedPromoCode && !dismissed &&
      <div className="banner p-2.5 flex gap-x-2 items-center justify-center bg-sunshine text-base text-center leading-none border-b border-t border-neutral-400">
        <div className="flex-auto" />
        <div className="flex-auto flex justify-center items-baseline gap-2">
          <span>
            Promo Code
            {" "}
            <span className="font-sans-demibold uppercase">
              {appliedPromoCode}
            </span>
            {" "}
            successfully applied.
          </span>
          <a
            className="underline hover:text-neutral-800 focus:text-neutral-800 active:text-neutral-800"
            href="/jewelry"
            onClick={handleLinkClicked}
          >
            Shop Now!
          </a>
        </div>
        <div className="flex-auto flex items-center justify-end">
          <div
            className="cursor-pointer size-4 rounded-full hover:bg-white hover:bg-opacity-60 transition-all duration-300"
            onClick={handleDismissed}
          >
            <i className="fa-light fa-xmark opacity-40" />
          </div>
        </div>
      </div>}
    </React.Fragment>
  );
};

PromoCodeBanner.defaultProps = {"promoCode": ""};

PromoCodeBanner.propTypes = {"cookies": PropTypes.object.isRequired};

export default withCookies(PromoCodeBanner);
