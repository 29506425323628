import PropTypes from "prop-types";

const SharedPropTypes = {
  "productDescriptor": PropTypes.shape({
    "designerName": PropTypes.string,
    "highlight": PropTypes.string,
    "id": PropTypes.number.isRequired,
    "imageUrl": PropTypes.string,
    "isStylistChoice": PropTypes.bool,
    "kindName": PropTypes.string,
    "memberPrice": PropTypes.string,
    "name": PropTypes.string,
    "ringSize": PropTypes.string
  })
};

SharedPropTypes.productDescriptors = PropTypes.arrayOf(SharedPropTypes.productDescriptor);

export {SharedPropTypes};
export default SharedPropTypes;
