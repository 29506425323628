/* eslint-disable quotes, react/destructuring-assignment */

import * as React from "react";
import Button from "../../../shared/components/Button";
import PropTypes from "prop-types";

export default class RingSizeSelection extends React.Component {

  static propTypes = {
    "customerId": PropTypes.string,
    "hideRingSizeSelection": PropTypes.func.isRequired,
    "ringSizes": PropTypes.array.isRequired,
    "updateWishListCallback": PropTypes.func.isRequired
  };

  static defaultProps = {"customerId": ""};

  constructor (props) {
    super(props);

    const {ringSizes} = this.props;
    this.state = ringSizes.reduce((products, product) => ({
      ...products,
      [product.ring_size]: product.wishlisted
    }), {});
  }

  selectSize = (event) => {
    event.persist();
    this.setState(prevState => ({[event.target.textContent]: !prevState[event.target.textContent]}));
  };

  updateWishListRequests = () => {
    const {hideRingSizeSelection, ringSizes, updateWishListCallback} = this.props;
    updateWishListCallback(
      ringSizes.reduce((sizes, size) => {
        const {id, "ring_size": ringSize, "wishlisted": originallyWishlisted} = size;
        const {[ringSize]: wishlisted} = this.state;

        return wishlisted === originallyWishlisted
          ? {...sizes}
          : {
            ...sizes,
            [ringSize]: {
              id,
              wishlisted
            }
          };
      }, {})
    );
    hideRingSizeSelection();
  };

  render () {
    const {customerId, hideRingSizeSelection, ringSizes} = this.props;
    let anyWishlisted = false;
    ringSizes.forEach((ringSize) => {
      if (anyWishlisted) {
        return;
      }
      const {wishlisted} = ringSize;
      anyWishlisted = wishlisted;
    });

    return (
      <>
        <button
          className="close ring-size-close"
          onClick={hideRingSizeSelection}
          type="button"
        >
          <span>
            ×
          </span>
        </button>
        <h6>
          Select a Size:
        </h6>
        <div className="ring-size-selection">
          {ringSizes.map((product, index) =>
            <React.Fragment key={`${product.product_name} ${product.ring_size}`}>
              <p
                className={this.state[product.ring_size]
                  ? "circle"
                  : ""}
                onClick={this.selectSize}
              >
                {product.ring_size}
              </p>
              { (index + 1) % 3 === 0 && <br />}
            </React.Fragment>)}
        </div>
        {customerId &&
        <Button
          classes={`${Object.values(this.state).filter(wishlisted => wishlisted).length > 0
            ? "btn-primary btn-small ringsize-btn"
            : "btn-secondary btn-small"} ringsize-btn`}
          onClick={this.updateWishListRequests}
          text={anyWishlisted
            ? "Update"
            : "Add to Wishlist"}
        />}
      </>
    );
  }
}
