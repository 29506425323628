/* eslint-disable max-params */
import API from "./API";

export default class EventsAPI {
  static trackCustomerEvent (eventName, eventDescription) {
    return $.ajax({
      "data": {
        "customer_event": {
          "description": eventDescription,
          "event_name": eventName,
          "time_triggered": new Date()
        },
        "customer_id": API.CUSTOMER_ID
      },
      "headers": API.authHeaders(),
      "method": "POST",
      "url": "/api/v1/events/create_customer_event"
    });
  }

  static trackContactEvent (eventName, eventDescription, anonymousContactId, pageView = false) {
    return $.ajax({
      "data": {
        "contact_event": {
          "anonymous_contact_id": anonymousContactId,
          "description": eventDescription,
          "event_name": eventName,
          "time_triggered": new Date()
        },
        "page_view": pageView
      },
      "headers": API.authHeaders(),
      "method": "POST",
      "url": "/api/v1/events/create_contact_event"
    });
  }

  static trackUserEvent (eventName, eventDescription, anonymousContactId = null) {
    if (API.CUSTOMER_ID) {
      return EventsAPI.trackCustomerEvent(eventName, eventDescription);
    }
    return EventsAPI.trackContactEvent(eventName, eventDescription, anonymousContactId);
  }

  static trackBrowserAbandonment (pdId) {
    const customerId = API.CUSTOMER_ID;
    if (!customerId) {
      return null;
    }

    return $.ajax({
      "data": {
        "customer_id": customerId,
        "pd_id": pdId
      },
      "headers": API.authHeaders(),
      "method": "POST",
      "url": "/api/v1/events/track_browser_abandonment"
    });
  }

  static trackCustomerOrContactEvent (eventName, eventDescription, pageView = false) {
    if (API.CUSTOMER_ID) {
      EventsAPI.trackCustomerEvent(eventName, eventDescription);
    } else {
      const annonymousContactid = getCookie("anonymous_contact_id"); // eslint-disable-line no-undef
      EventsAPI.trackContactEvent(
        eventName,
        eventDescription,
        annonymousContactid,
        pageView
      );
    }
  }
}
