import PropTypes from "prop-types";
import React from "react";

const LoadingSpinner = ({color, size, width}) => (
  <div
    className="loading-spinner-container"
    style={{
      "height": `${size}px`,
      "width": `${size}px`
    }}
  >
    <div
      className="loading-spinner"
      style={{
        "borderBottom": `solid ${width}px ${color}`,
        "height": `${size}px`,
        "width": `${size}px`
      }}
    />
  </div>
);

LoadingSpinner.propTypes = {
  "color": PropTypes.string,
  "size": PropTypes.number,
  "width": PropTypes.number
};

LoadingSpinner.defaultProps = {
  "color": "white",
  "size": 15,
  "width": 1
};

export default LoadingSpinner;
