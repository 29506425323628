import * as React from "react";
import PropTypes from "prop-types";

export default class Button extends React.PureComponent {

  static propTypes = {
    "classes": PropTypes.string,
    "dataTestAttribute": PropTypes.string,
    "disabled": PropTypes.bool,
    "loading": PropTypes.bool,
    "onClick": PropTypes.func,
    "text": PropTypes.string.isRequired
  };

  static defaultProps = {
    "classes": "",
    "dataTestAttribute": "",
    "disabled": false,
    "loading": false,
    "onClick": () => null
  };

  handleClick = () => {
    const {onClick} = this.props;
    onClick();
  };

  render () {
    const {classes, dataTestAttribute, disabled, loading, text} = this.props;

    return (
      <button
        className={`btn ${classes}`}
        data-test={dataTestAttribute}
        disabled={disabled}
        onClick={this.handleClick}
        type="button"
      >
        {text}
        {loading &&
          <img
            className="spinner"
            src="https://s3.amazonaws.com/rocksbox/karat/spinner.gif"
            width="20"
          />}
      </button>
    );
  }
}
